
@font-face {
  font-family: 'Swash';
  src: local('Swash'), url(../fonts/DeliusSwashCaps-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Comfort';
  src: local('Comfort'), url(../fonts/Comfortaa-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Homemade';
  font-display: swap;
  src: local('Homemade'), url(../fonts/FrederickatheGreat-Regular.ttf) format('truetype');
}


@import "~bootstrap/scss/bootstrap";
html,
body {
  height: 100%;
  font-size: 16px;
  background-color: rgb(245, 245, 245);
  font-family: Comfort;
  min-width: 305px;

}


body p,
body em {
  font-size: 1.2em;
}


.row{
  margin-top: 20px;
}


.container-fluid {
  padding-left: 10%;
  padding-right: 10%;
}
